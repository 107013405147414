<template>
  <main class="about page">
    <section class="mainContent">
      <quizLogo/>
      <h1>This is an about page</h1>
    </section>
     <sideImage :imgSrc="details.logo"/>
  </main>
</template>

<script>
import quizLogo from '@/components/quizLogo.vue'
import sideImage from '@/components/sideImage.vue'

export default {
  components: {
    quizLogo,
    sideImage
  },
  computed: {
    details () {
      return this.$store.state.details
    }
  },
  mounted () {
    // this.details = this.$store.state.details
    // this.$store.commit('SET_IMG', this.details.logo)
  }
}
</script>
