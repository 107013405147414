<template>
  <div class="logo" >
    <router-link to="/"><img :src="details.logo" alt="cité des métiers"></router-link>
  </div>
</template>

<script>
export default {
  name: 'quizLogo',
  computed: {
    details () {
      return this.$store.state.details
    }
  }
}
</script>
